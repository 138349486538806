
@import url('https://fonts.googleapis.com/css2?family=Rochester:wght@100;400&display=swap');
@media (max-width: 500px) {
    .imagephone { 
      display: contents!important;
    }
    .imagephone > img {
        
    display: block!important;
    margin-left: auto!important;
    margin-right: auto!important;
    }
  }
.active {
    color: #438a5e;
    text-decoration: none;
}

.active>.menuItem {
    border-bottom: 2px solid #438a5e;
}

#tsparticles {
    position: absolute;
    left: 0;
    margin: 10px;
    z-index: 0;
     height: 80%;
    width: 90%;
}
.linkMenuItem {
    text-decoration: none;
    color: black;
}

.menuItem:hover {
    color: #438a5e;
    border-bottom: 2px solid #438a5e;
}

.menuItem {
    display: inline-block;
    padding: 1%;
}

.backButton:hover {
    cursor: pointer;
    color: #438a5e;
}

.backButton {
    background: #f8faff;
    margin-bottom: 40px !important;
    margin-top: 15px !important;
    cursor: pointer;
    min-height: 80px;
    box-shadow: 0 1.8px 2.2px rgb(0 0 0 / 3%), 0 3.7px 1.3px rgb(0 0 0 / 5%), 0 1.5px 10px rgb(0 0 0 / 6%),
        0 22.3px 17.9px rgb(0 0 0 / 7%), 0 4.8px 33.4px rgb(0 0 0 / 9%), 0 10px 80px rgb(0 0 0 / 12%);

    border-radius: 10px;
}

.backButtonwrapper {
    padding-top: 25px;
    padding-left: 30px;
}

.backButtonICon {
    vertical-align: middle;
    cursor: pointer;
}

.verticalAlign {
    margin-top: auto;
    margin-bottom: auto;
}

.link {
    text-decoration: none;
    color: inherit;
}

.AlignCenter {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    width: fit-content !important;
}

.msgIcons {
    color: #438a5e;
    font-size: 1.3rem;
    float: right;
}

.actionIcons {
    color: #8b66f1;
    font-size: 1.3rem;
    float: right;
}

.tooltipMsg {
    font-size: 0.9rem;
    border-radius: 5px;
}

.inclinedImgContainer {
    border: 5px solid orange;
    transform: rotate(5deg);
    border-radius: 10px;
    width: 40vw;
}

.inclineImg {
    border-radius: 5px;
    transform: rotate(-5deg);
    width: 39vw;
}

.inclineImg:hover {
    transform: rotate(10deg);
}

.CardActions {
    max-height: fit-content;
    min-height: 120px;
}

.inclinedContainer {
    border-radius: 10px;
    width: 40vw;
    margin-top: -100px;
}

.descriptionWrapper {
    border-radius: 10px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
    margin-left: auto;
    margin-right: auto;
    background: rgba(0, 87, 142, 0.9);
    margin: 0 auto;
    max-width: 1000px !important;
    position: relative;
    text-align: center;
    padding: 15px;
}

.msgRead {
    background-color: white;
}

.sideBox {
    border-radius: 10px;

    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
}

.sideBoxWrapper {
    border-right: 2px solid rgb(153 153 255);
    margin-top: 5px;
}

.stf__item {
    border-left: 1px solid rgb(201, 201, 201);
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
}

.center {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
}

.menuItemList {
    background-color: white !important;
    border: 1px solid #f3f3f3 !important;
}

.actionSection {
    background: #f5f5f5;

    padding: 30px !important;
}

.actionSectionHeader {
    margin: 10px !important;
    margin-bottom: 20px !important;
}

.customDrop {
    padding: 50px;
}

.navbarActive {
    background-color: white!important;
}

.navbar {
    background-color: white!important;
}

.alignCenter {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
}

.pulse {
    position: absolute;
    top: 35%;
    left: 10%;
    transform: translate(-505, -50%);
    width: 10px;
    height: 10px;
    background: #206aa7;
    border: 2px solid #206aa7;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 0 0 0 36px #45237a, 0 0 0 40px #206aa7;
}

.pulse:before,
.pulse:after {
    content: "";
    position: absolute;
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
    border: 2px solid #206aa7;
    border-radius: 50%;
    animation: animate 2s linear infinite;
}

.pulse:after {
    animation-delay: 1s;
}

@keyframes animate {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(37, 35, 150, 0.4);
        box-shadow: 0 0 0 0 rgba(93, 67, 139, 0.4);
    }

    70% {
        -moz-box-shadow: 0 0 0 30px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 30px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

.carousel-root {
    width: 64% !important;
    margin: auto !important;
    margin-top: 3% !important;
}

.carousel {

    height: 100%;
    margin: 10%;

}

.carousel .slide {
    color: black;
    height: 100%;

}

.carousel .slide img {
    width: 139px !important;
    border-radius: 50%;
}

.myCarousel {
    background: #fff;
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 60px;
    padding-bottom: 30px;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
}

.carousel .control-dots {
    padding-left: 5px !important;
    outline: 0;
    bottom: 5% !important;
}

.myCarousel h3 {
    color: #787878;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-size: 17px;
    text-align: left;
}

.myCarousel h4 {
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #787878;
    font-size: 14px;
}

.myCarousel p {
    font-weight: 100 !important;
    line-height: 29px !important;
    color: #222;
    font-size: 15px;
    font-family: sans-serif;
    max-height: 100%;
}

.myCarousel p:before {
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
}

.myCarousel p:after {
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
}

.carousel .control-dots .dot {
    box-shadow: none !important;
    background: #454545 !important;
    outline: 0;
}

.carousel.carousel-slider .control-arrow {
    background: #217edd !important;

    height: 50px !important;
    position: absolute;
    top: 35% !important;
}

.App {
    text-align: center;
}

@media only screen and (max-width: 934px) {
    .carousel-root {
        outline: 0;
        width: 93% !important;
        margin: auto !important;
    }

    .carousel.carousel-slider .control-arrow {
        display: none !important;
    }

    .myCarousel {
        background: #fafafa;
        padding-top: 1%;
        padding-bottom: 1%;
        padding-left: 1%;
        padding-right: 1%;
        border: 1px solid #ddd;
        height: 100%;
        width: 300px;

    }

    .carousel .slide img {
        width: 24% !important;
        border-radius: 50%;
    }
}

.control-dots {
    display: none;
}

.aos-init {
    overflow-x: hidden !important;
    overflow-y: hidden !important
}


.control-arrow {
    background: #217edd !important;
    border-radius: 82px !important;

}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

a {
    text-decoration: none!important;

}